<template>
  <v-container>
    <validation-observer ref="observer">
      <form class="p-2" @submit.prevent="submit">
        <validation-provider v-slot="{ errors }" name="Name" rules="required">
          <v-text-field v-model="aircraft_types.name" :error-messages="errors" label="Name" required outlined>
          </v-text-field>
        </validation-provider>

        <v-btn type="submit" color="primary" class="ma-10 pa-5">
          Update
        </v-btn>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "AircraftTypeRead",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    aircraft_types: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    updateBrownse() {
      this.$emit("updateBrownse");
    },
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        await Request.update(`${this.$IYA.URI.AIRCRAFT_TYPES__EDIT}`, this.aircraft_types).then(response => {
          if (response.data.success) {
            this.$swal(`Done !`, `Action accomplished`, `success`);
            this.updateBrownse();
          } else {
            // eslint-disable-next-line
            if (response.data.error) {
              this.$swal(`Failed !`, `${response.data.error}`, `error`);
            } else {
              this.$swal(`Failed !`, `${response.message}`, `error`);
            }
          }
        });
        util.isLoading();
      }
    },
  },
};
</script>
